// Auth
export const ACCESS_CHECK = "auth/access-check"
export const JWT_LOGIN = "auth/login"

// Admin
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile"

// Users
export const GET_USERS = "/users/list"
export const CREATE_USER = "/users/create"
export const UPDATE_USER = "/users/update"
export const DELETE_USER = "/users/delete"

// Accounts/Onboarding
export const GET_ACCOUNT = "/acc/list"
export const CREATE_ACCOUNT = "/acc/create"
export const ASSIGN_ACCOUNT_TO_USER = "/acc/assign-user"
export const DELETE_ACCOUNT = "/acc/delete"
export const UPDATE_TIMEZONE_ACCOUNT = "/acc/update-timezone"
export const GET_OB_TITLES = "/acc/ob/titles"
export const GET_OB_BROKERAGES = "/acc/ob/brokerages"
export const GET_OB_ACCRS = "/acc/ob/accreditations"
export const GET_OB_BOARDS = "/acc/ob/boards"
export const GET_OB_SERVICE_TYPES = "/acc/ob/service-types"
export const APPROVE_OB = "/acc/ob/approve"
export const ACCOUNT_DEV_STATUS_UPDATE = "/acc/dev-status-update"
export const UPDATE_ACCOUNT_MLS_CONFIG_CREDENTIALS = "/acc/update-mls-config-credentials"
export const UPDATE_ACCOUNT_MLS_CONFIG_STATUS = "/acc/update-mls-config-status"
export const ACCOUNT_ACTIVE_RECURRING_COUNT = "/acc/active-recurring-count"
export const ACCOUNT_ACTIVE_RECURRING_COUNT_DOWNLOAD = "/acc/active-recurring-count/download"
export const GET_CUSTOM_FIELDS_LIST = "/acc/custom-fields-list/";
export const UPDATE_LICENSE_CONFIG = "/acc/update-license-config";
export const GET_LICENSE_CRON_LOGS = "/acc/license-cron-logs";

// Account Config
export const GET_ACCOUNT_CONFIG = "/acc/config"
export const UPDATE_ACCOUNT_CONFIG = "/acc/update-config"
export const UPDATE_ACCOUNT_SSO_CONFIG = "/acc/update-sso-config"
export const GET_MEMBER_PORTAL_COLUMNS = "/acc/member-portal-columns"

// DB Updations
export const GET_DB_UPDATIONS = "/acc/db-updates/list"
export const ADD_NEW_DB_UPDATION = "/acc/db-updates/create"
export const DEPLOY_DB_UPDATION_TO_PROD = "/acc/db-updates/deploy-prod"
export const DEPLOY_DB_UPDATION_TO_TEST = "/acc/db-updates/deploy-test"
export const ACC_DB_RUN_SEEDER = "/acc/db-updates/run-seeder"

// Accreditations
export const GET_ACCR = "/accreditations/list"
export const CREATE_UPDATE_ACCR = "/accreditations/create-update"
export const DELETE_ACCR = "/accreditations/delete"

// Titles
export const GET_TITLE = "/titles/list"
export const CREATE_UPDATE_TITLE = "/titles/create-update"
export const DELETE_TITLE = "/titles/delete"

// Languages
export const GET_LANGUAGE = "/languages/list"
export const CREATE_UPDATE_LANGUAGE = "/languages/create-update"
export const DELETE_LANGUAGE = "/languages/delete"

// Specialties
export const GET_SPECIALTY = "/specialties/list"
export const CREATE_UPDATE_SPECIALTY = "/specialties/create-update"
export const DELETE_SPECIALTY = "/specialties/delete"

// Titles
export const SOCIAL_PROFILES_LIST = "/social-post/profiles-list"
export const SOCIAL_POSTS_LIST = "/social-post/posts/list"
export const SOCIAL_PROFILES_DELETE = "/social-post/delete-profile"
export const TWITTER_AUTH_URL = "/social-post/twitter/auth-url"
export const TWITTER_AUTH_CALLBACK = "/social-post/twitter/auth-callback"
export const SOCIAL_POSTS_CREATE = "/social-post/posts/create"
export const LINKEDIN_AUTH_URL = "/social-post/linkedin/auth-url"
export const LINKEDIN_AUTH_CALLBACK = "/social-post/linkedIn/auth-callback"
export const FACEBOOK_AUTH_URL = "/social-post/facebook/auth-url"
export const FACEBOOK_AUTH_CALLBACK = "/social-post/facebook/auth-callback"
export const INSTAGRAM_AUTH_URL = "/social-post/instagram/auth-url"
export const INSTAGRAM_AUTH_CALLBACK = "/social-post/instagram/auth-callback"

// Dataflow
export const GET_DATASETS = "/df/datasets/list"
export const GET_DATASET = "/df/dataset"
export const GET_DATASET_DATA = "/df/datasets/data"
export const DELETE_DATASET_DATA = "/df/datasets/data/delete"
export const CREATE_UPDATE_DATASET = "/df/datasets/create-update"
export const DELETE_DATASET = "/df/datasets/delete"
export const GET_SOURCES = "/df/sources/list"
export const CREATE_SOURCE = "/df/sources/create"
export const UPDATE_SOURCE_STATUS = "/df/sources/update-status"
export const DELETE_SOURCE = "/df/sources/delete"
export const GET_SOURCE = "/df/source"
export const GET_SOURCE_DATA = "/df/sources/data"
export const SAVE_WEBHOOK_CONFIG = "/df/sources/webhook-config"
export const DELETE_SOURCE_DATA = "/df/sources/data/delete"
export const DATAFLOW_IDENTITIES_ATTRIBUTES_LIST = 'df/identities/attributes?options=1';

// Settings
export const GET_SETTINGS = "/settings/list"
export const CREATE_UPDATE_SETTING = "/settings/create-update"

// SameGate
export const CREATE_SG_ORG_USER = "/acc/create-sg-merchant"
export const CONNECT_PAYMENT_GATEWAY_SG = "/acc/sg/connect-payment-gateway"
export const UPDATE_SG_PAYMENT_GATEWAY_SETTINGS = "/acc/sg/update-payment-gateway-settings"

export const WEBSITES_LIST_GET = "/website/list"
export const WEBSITES_UPDATION_DETAILS_GET =
  "/website/websites-updation-details"
export const WEBSITES_UPDATION_DETAILS_SET =
  "/website/websites-updation-details/"
export const WEBSITES_DEPLOYMENT_STATUS_UPDATE =
  "/website/update-deployment-status"
export const WEBSITES_CAPTCH_UPDATE = "/website/update-captcha"
export const WEBSITES_CONFIG_DETAILS = "/website/config-details"

export const WEBSITES_SYSTEM_PAGES_LIST_GET = "/website/pages/system"
export const WEBSITES_SYSTEM_PAGES_DELETE = "website/pages/delete/"
export const WEBSITES_SYSTEM_PAGES_CREATE_UPDATE = "/website/pages/system/create-update"
export const WEBSITES_SYSTEM_PAGES_SORT_ORDER_UPDATE = "/website/pages/system/sort-order-update"

// Websites Themes
export const WEBSITES_THEMES_LIST = "/website/themes/list"
export const WEBSITES_THEMES_UPDATE_STATUS = "/website/themes/update-status"

// Post Give Aways
export const GET_POSTS = "/misc/posts/list"
export const CREATE_UPDATE_POST = "/misc/posts/create-update"
export const DELETE_POST = "/misc/posts/delete"

// Raffle Items
export const GET_RAFFLE_ITEMS = "/misc/raffle-items/list"
export const CREATE_UPDATE_RAFFLE_ITEM = "/misc/raffle-items/create-update"
export const UPDATE_RAFFLE_ITEM_DATA = "/misc/raffle-items/update-data"
export const DELETE_RAFFLE_ITEM = "/misc/raffle-items/delete"

// Imports
export const PARSE_CSV = "/import/contacts/parse-csv"
export const GET_FIELDS = "/import/custom-fields/list"
export const IMPORT_CONTACTS_DATA = "/import/contacts/import-data"
export const IMPORT_EVENTS_DATA = "/import/events/import-data"
export const IMPORT_BILLABLE_ITEMS_DATA = "/import/billable-items/import-data"
export const IMPORT_CC_TOKENS_DATA = "/import/cc-tokens-sg/import-data"
export const IMPORT_AUTO_RENEW_DATA = "/import/auto-renew-sg/import-data"
export const IMPORT_CONTACTS_BIO_DATA = "/import/contacts/import-bio-data"
export const IMPORT_CONTACTS_LANGUAGES_DATA = "/import/contacts/import-languages-data"
export const IMPORT_CONTACTS_VALIDATE_DATA = "/import/contacts/validate-import-data"
export const IMPORT_CONTACTS_ACCREDITATIONS_DATA = "/import/contacts/import-accreditations-data"
export const IMPORT_CONTACTS_AREAS_DATA = "/import/contacts/import-areas-data"
export const IMPORT_BROKERAGE_ROLES_DATA = "/import/brokerage-roles/import-data"
export const IMPORT_M1_SYNC_DATA = "/import/m1-sync/import-data"

// Account DB: Profiles
export const GET_PROFILES = "/acc/profiles/list"

// Account DB: Contacts
export const DELETE_CONTACTS = "/acc/contacts/delete"

// Master Users
export const GET_MASTER_USERS = "/master-users/list"
export const CREATE_UPDATE_MASTER_USER = "/master-users/create-update"
export const DELETE_MASTER_USER = "/master-users/delete"

// MLS
export const GET_MLS_VIEW = "/mls/view"
export const SAVE_MLS_VIEW = "/mls/save-view"
export const DELETE_MLS_VIEW = "/mls/delete-view"
export const GET_MLS_VIEW_USERS = "/mls/view-users"
export const CREATE_UPDATE_VIEW_USER = "/mls/view-users/create-update"
export const DELETE_VIEW_USER = "/mls/view-users/delete"
export const MLS_CRON_RESET = "/mls/cron-reset"

// Account DB: Custom Fields
export const CREATE_CUSTOM_FIELD = "/acc/custom-fields/create"

// Webinars
export const GET_WEBINARS = "agentbook/webinars/list"
export const CREATE_UPDATE_WEBINAR = "agentbook/webinars/create-update"
export const DELETE_WEBINAR = "agentbook/webinars/delete/"

// Sponsers
export const GET_SPONSERS = "agentbook/sponsers/list"
export const CREATE_UPDATE_SPONSER = "agentbook/sponsers/create-update"
export const DELETE_SPONSER = "agentbook/sponsers/delete/"
export const STATUS_UPDATE_ALL_SPONSERS = "agentbook/sponsers/status/"
export const SORT_ORDER_UPDATE_ALL_SPONSERS =
  "agentbook/sponsers/sort-order-update"
export const GET_SPONSERS_SETTINGS = "agentbook/sponsers/settings"

export const CAMPAIGNS_LIST_GET = "/campaigns/list";
export const CAMPAIGNS_LIST_OVERVIEW = "/campaigns/overview";

export const SUPPRESSIONS_LIST_GET_LIST_TYPES = "/sendgrid/suppressions/types-list/";
export const SUPPRESSIONS_LIST_GET_EMAILS_LIST = "/sendgrid/suppressions/emails-list/";
export const SUPPRESSIONS_DELETE_EMAILS = "/sendgrid/suppressions/delete";

export const SEGMENT_OPERATORS_LIST = 'acc/segments/operators-list';

export const SALES_SOURCE_LIST = "/sales/source/list";
export const SALES_SOURCE_CREATE = "/sales/source/create";

export const SALES_COMPANY_GAME_MODE = "/sales/company/game-mode";

export const SALES_COMPANY_LIST = "/sales/company/list";
export const SALES_COMPANY_CREATE = "/sales/company/create";
export const SALES_COMPANY_UPDATE_VALUE = "/sales/company/update-value";
export const SALES_COMPANY_STATE_ASSOCIATION_LIST = "/sales/company/state-association-list/";

export const SALES_COMPANY_PEOPLES_LIST = "/sales/company/peoples/list/";
export const SALES_COMPANY_PEOPLES_CREATE = "/sales/company/peoples/create";

export const SALES_COMPANY_NOTES_LIST = "/sales/company/notes/list/";
export const SALES_COMPANY_NOTES_CREATE = "/sales/company/notes/create";

export const SALES_PROSPECT_REASONS_LIST = "/sales/prospect-reasons/list";
export const SALES_PROSPECT_REASONS_CREATE_UPDATE = "/sales/prospect-reasons/create-update";

export const SALES_COMPANY_PROSPECT_UPDATES_LIST = "/sales/company/prospect-updates/list/";
export const SALES_COMPANY_PROSPECT_UPDATES_CREATE_UPDATE = "/sales/company/prospect-updates/create-update";
export const SALES_COMPANY_PROSPECT_UPDATES_STATUS_UPDATE = "/sales/company/prospect-updates/status-update";

// Team Members
export const GET_TEAM_MEMBERS = "/team-members/list"
export const CREATE_TEAM_MEMBER = "/team-members/create"
export const UPDATE_TEAM_MEMBER = "/team-members/update"
export const DELETE_TEAM_MEMBER = "/team-members/delete/"

// Account Tokens
export const GET_ACCOUNT_TOKENS = "/account-tokens/list"
export const CREATE_UPDATE_ACCOUNT_TOKEN = "/account-tokens/create-update"
export const UPDATE_ACCOUNT_TOKEN_STATUS = "/account-tokens/update-status"
export const DELETE_ACCOUNT_TOKEN = "/account-tokens/delete"
export const FIND_AND_CREATE_ACCOUNT_TOKEN = "/account-tokens/find-and-create";
export const UPDATE_ACCOUNT_TOKEN_ENDPOINTS = "/account-tokens/update-endpoints"

// Tutorials
export const TUTORIALS_CATEGORY_LIST = "/tutorials/category/list"
export const TUTORIALS_CATEGORY_CREATE_UPDATE = "/tutorials/category/create-update"
export const TUTORIALS_CATEGORY_DELETE = "/tutorials/category/delete/"
export const TUTORIALS_CATEGORY_SORT_ORDER_UPDATE = "/tutorials/category/sort-order-update"

export const TUTORIALS_LIST = "/tutorials/list"
export const TUTORIALS_CREATE_UPDATE = "/tutorials/create-update"
export const TUTORIALS_DELETE = "/tutorials/delete/"
export const TUTORIALS_SORT_ORDER_UPDATE = "/tutorials/sort-order-update"
export const TUTORIALS_STATUS_UPDATE = "/tutorials/status-update"

// Listings Feeds
export const LISTINGS_FEEDS_LIST = "/feeds/list"
export const LISTINGS_FEEDS_CREATE_UPDATE = "/feeds/create-update"
export const LISTINGS_FEEDS_DELETE = "/feeds/delete/"
export const LISTINGS_FEEDS_CLEAR_DATA = "/feeds/clear-data/"
export const LISTINGS_FEEDS_STATUS_UPDATE = "/feeds/cron-status-update"
export const LISTINGS_FEEDS_CONFIGURATION_DETAILS = "/feeds/configuration-details/"
export const LISTINGS_FEEDS_UPDATE_CONFIGURATION_DETAILS = "/feeds/update-configuration-details"
export const LISTINGS_FEEDS_SET_LISTINGS_RESOURCE = "/feeds/set-listings-resource/"

export const LISTINGS_FEEDS_SAVE_TOKEN = "/feeds/save-token"
export const LISTINGS_FEEDS_GET_TOKEN_ID = "/feeds/get-token-id"
export const LISTINGS_INDEXES_LIST = "/indexes/list"
export const LISTINGS_INDEXES_CREATE_UPDATE = "/indexes/create-update"
export const LISTINGS_INDEXES_DROP = "/indexes/drop"

// SSO
export const GET_SSO_LIST = "/sso/list"

// AgentBook SSO
export const GET_SSO_CLIENTS_LIST = "/sso/clients/list"
export const CREATE_OR_UPDATE_SSO_CLIENT = "/sso/clients/create-update"
export const DELETE_SSO_CLIENT = "/sso/clients/delete/"

// Shared Elements Library
export const GET_SEL_LIST = "/shared-elements-library/list"
export const GET_SEL_DELETE = "/shared-elements-library/delete/"
export const GET_SEL_CREATE_UPDATE = "/shared-elements-library/create-update"

// Quickbooks
export const GET_QB_CONFIG = "/acc/qb/config"
export const GET_QB_ENTITIES = "/acc/qb/entities-list"
export const EXPORT_QB_ENTITIES = "/acc/qb/entities-export"

// Account Integrations
export const SAVE_INTEGRATION_SETTINGS = "/acc/integrations/save"

// Cities
export const GET_CITIES = "/misc/cities/list"

// Notes
export const IMPORT_NOTES = "/import/notes"
export const DELETE_NOTES = "/delete/notes"

// M1 Sync
export const GET_M1_SYNC_LOGS = "/m1-sync/logs"
export const GET_MEMBER_OFFICE_M1 = "/m1-sync/get-member-office"
export const GET_DOWNLOAD_FILE_BY_ID = "/m1-sync/download-file"
